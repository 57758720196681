import ImageIcon from "@rsuite/icons/Image";
import TextImageIcon from "@rsuite/icons/TextImage";
import React, { useEffect, useState } from "react";
import { APi } from "../../Api";

import axios from "axios";
import moment from "moment";
import { useRecoilState } from "recoil";
import {
  Button,
  IconButton,
  Input,
  Loader,
  Message,
  Modal,
  SelectPicker,
  Uploader,
} from "rsuite";
import Swal from "sweetalert2";
import { ENDPOINTS } from "../../Api/enpoints";
import { SaleUmrahAtom } from "../../Atoms/saleOmrah.atom";
import Responsive from "../../Components/Responsive";
import {
  AgeType,
  CustomerType,
  Gender,
  MaritalStatus,
  Titles,
} from "../../Constants/types";
import mrzparser from "../../Helpers/mrzparser";
import { CustomerVoModel } from "../../Models/SaleVoModel";
import PriceItem from "./PriceItem";
import { BASE_URL } from "../../Config/api.config";
import { OrderUmrahModel } from "../../Models/orderUmrahModel";
import { MdImage } from "react-icons/md";
import { countries } from "../../Constants/countries";
import validate from "../../Helpers/validate";
import { createAPIEndpoint } from "../../Api/authenticated.requests";

export default function PricesOmrah() {
  const [validatePassport, setvalidatePassport] = useState("");
  const [error, setError] = useState("");
  const [verify, setverify] = useState("");
  const [verifyRs, setverifyRs] = useState("");
  const [forcasts, setforcasts] = useState([]);
  const [clientId, setclientId] = useState(0);
  const [open, setopen] = useState(false);
  const [model, setmodel] = useRecoilState(SaleUmrahAtom);
  const [loadingPassport, setloadingPassport] = useState(false);
  const [loading, setloading] = useState(false);
  const [packages, setpackages] = useState([]);

  const [passportFile, setpassportFile] = useState(null);

  const fetchPackages = (q) => {
    if (typeof q === "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.PackageUmrah + "/getForPrices", { q })
        .customGet()
        .then((res) => {
          setpackages(res.data.data);
        });
    }
  };
  useEffect(() => {
    fetchPackages();
    let c = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth")).clientId
      : 0;
    setclientId(c);
    APi.createAPIEndpoint(ENDPOINTS.Forcast, { clientId: c, date: new Date() })
      .customGet()
      .then((res) => setforcasts(res.data.data));
  }, []);
  const readfile3 = async ($event) => {
    const files = $event.target.files;
    setloadingPassport(true);
    const file = files[0];
    setpassportFile(file);
    const formData = new FormData();

    // Update the formDalet ta object
    formData.append("File", file, file.name);
    let passportPicture = "";

    let _res = await APi.createAPIEndpoint(
      APi.ENDPOINTS.File + "/Passport"
    ).upload1(file);
    console.log(passportPicture);
    if (_res) {
      passportPicture = _res.data;
      setmodel((pr) => ({
        ...pr,
        customer: { ...pr.customer, passportPicture },
      }));
    }
    axios
      .post("https://scan.kounouz.travel/api/Scan/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setmodel((prev) => {
          let customer = { ...model.customer, passportPicture };
          //________________
          customer.birthDate = res.data.birthDate;
          customer.passportNumber = res.data.passportNumber;
          let country = countries.find(
            (c) => c.alpha3 == res.data.nationalityCountryId || c.iso == "TN"
          );
          if (country) {
            console.log(country);
            customer.nationality = country.code;
            customer.addressCountry = country.code;
            customer.birthCountry = country.code;
            customer.passportDeliveryCountry = country.code;
            customer.birthCity = country.country;
            customer.city = country.country;
            customer.passportDeliveryCity = country.country;
          }

          customer.mrz = res.data.mrz;
          customer.expirationPassportDate = res.data.passportExpiryDate;
          customer.lastNameAr = res.data.arlastName;
          customer.firstNameAr = res.data.arfirstName;
          customer.lastName = res.data.lastName;
          customer.firstName = res.data.firstName
            ? res.data.firstName.replace(res.data.lastName, "")
            : "";
          customer.name =
            res.data.firstName !== res.data.lastName
              ? res.data.firstName + " " + res.data.lastName
              : res.data.firstName;
          customer.gender = res.data.gender;
          customer.title = res.data.gender;
          customer.picture = res.data.pilgrimPicture;
          customer.job = "nothing";
          customer.status = 2;
          try {
            let parsedMrz = mrzparser.parse(res.data.mrz);
            customer.cin = parsedMrz.personalNumber.split(" ")[0];
            console.log(parsedMrz);
            //let parsedMrzExpiry = mrzparser.parse( res.data.mrz).expiry;

            let formatted = new Date(res.data.passportExpiryDate);
            var val = Date.parse(formatted);
            if (!isNaN(val)) {
              var d = new Date(val);
              var f = new Date();
              f.setDate(f.getDate() + 15);
              f.setMonth(f.getMonth() + 6);
              if (d < f && d > new Date()) {
                setvalidatePassport("Probleme de date d'expiration");
              } else {
                setvalidatePassport("");
              }
            }

            // console.log(formatted);
            formatted.setFullYear(formatted.getFullYear() - 5);

            customer.deliveryPassportDate = formatted;
            console.log(moment(customer.deliveryPassportDate));
            customer.deliveryPassportDate = moment(
              customer.deliveryPassportDate
            )
              .add(1, "days")
              .format("YYYY-MM-DD");
            console.log(customer.deliveryPassportDate);
          } catch {}
          return { ...prev, customer };
        });
        setloadingPassport(false);
      })
      .catch((e) => setloadingPassport(false));
  };
  useEffect(() => {
    let formatted = new Date(model.customer.expirationPassportDate);
    var val = Date.parse(formatted);
    if (!isNaN(val)) {
      var d = new Date(val);
      var f = new Date();
      f.setDate(f.getDate() + 15);
      f.setMonth(f.getMonth() + 6);
      if (d < f) {
        setvalidatePassport("Probleme de date d'expiration");
      } else {
        setvalidatePassport("");
      }
    }
  }, [model.customer.expirationPassportDate]);
  // const reset = setmodel(new OrderUmrahModel());
  const save = () => {
    let msg = validate(model.customer, [
      { firstName: "Prénom" },
      { firstNameAr: "Prénom Arabe" },
      { lastName: "Nom" },
      { lastNameAr: "Nom Arabe" },
      { fatherName: "Nom du pére" },
      { phoneNumber: "Numero de tél" },

      // { clientId: "Client" },
    ]);
    if (msg) setError(msg);
    else if (validatePassport) {
    } else {
      console.log(model);

      setloading(true);

      let m = {
        ...model,
      };

      console.log(m);
      delete m.client;
      setError("");

      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah")
        .create(m)
        .then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setloading(false);
          setopen(false);
          setmodel(new OrderUmrahModel());
        })
        .catch((e) => {
          setloading(false);
          if (e.response) {
            setError(e.response.data);
            Swal.fire({
              position: "top-center",
              icon: "warning",
              title: e.response.data,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    }
    // }
  };
  const check = () => {
    if (verify.length == 8)
      createAPIEndpoint(ENDPOINTS.Survey + "/verifyCoupon")
        .create({ coupon: verify })
        .then((res) => {
          setmodel((prev) => ({
            ...prev,
            couponReduction: 200,
            coupon: verify,
          }));
          setverifyRs("");
        })
        .catch((e) => {
          setverifyRs(e.response.data);
        });
  };
  return (
    <div>
      {forcasts &&
        forcasts.map((el, i) => (
          <Responsive xl={4} l={4} m={6} key={i} className="p-10">
            <PriceItem
              ident={i}
              packages={packages}
              clientId={clientId}
              item={el}
              onChoose={(_m) => {
                let m = { ...model, ..._m };
                m.clientId = clientId;
                m.disponibilityId = el.disponibilityId;
                m.packageUmrahId = el.packageUmrahId;
                m.date = new Date();
                console.log(m);
                setmodel(m);
                setopen(true);
              }}
            />
          </Responsive>
        ))}
      <Modal
        style={{ maxHeight: "95vh", overflow: "auto" }}
        size="lg"
        overflow={false}
        open={open}
        onClose={() => {
          setopen(false);
          setmodel(new OrderUmrahModel());
          setError("");
        }}
      >
        <Modal.Header>
          <Modal.Title>Ajouter Vente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {validatePassport && (
              <Message showIcon type="error">
                {validatePassport}
              </Message>
            )}
            {error && (
              <Message showIcon type="error">
                {error}
              </Message>
            )}
            <div style={{ textAlign: "right" }}>
              {" "}
              <input
                id="hidden3"
                style={{
                  visibility: "hidden",
                  width: "0",
                  height: 0,
                  overflow: "hidden",
                }}
                type="file"
                onChange={readfile3}
                accept=".jpg,.jpeg,.png"
              />
              <IconButton
                loading={loadingPassport}
                icon={<MdImage />}
                onClick={() => {
                  const el = document.querySelector("input#hidden3");
                  console.log(el);
                  el.click();
                }}
                color="green"
                appearance="primary"
              ></IconButton>
              <br></br>
              <div
                style={{
                  width: "170px",
                  heigth: "90px",
                  display: "inline-block",
                }}
              >
                {" "}
                {(passportFile ||
                  (model.customer && model.customer.passportNumber)) && (
                  <Uploader
                    // listType="picture"
                    autoUpload={false}
                    action="#"
                    draggable
                    fileListVisible={false}
                    // onUpload={}
                    onChange={(files) => {
                      APi.createAPIEndpoint(APi.ENDPOINTS.File + "/Passport")
                        .upload1(files[files.length - 1].blobFile)
                        .then((res) =>
                          setmodel((pr) => ({
                            ...pr,
                            customer: {
                              ...pr.customer,
                              passportPicture: res.data,
                            },
                          }))
                        );
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        width: "170px",
                        heigth: "90px",
                        display: "inline-block",
                        marginTop: "5px",
                      }}
                    >
                      {" "}
                      {model.customer.passportPicture ? (
                        <img
                          alt=""
                          src={
                            BASE_URL +
                            "Uploads/" +
                            model.customer.passportPicture +
                            "?" +
                            Date.now()
                          }
                          width="170"
                          height="90"
                          style={{
                            border: "1px dashed #888",
                            borderRadius: "5px",
                          }}
                        />
                      ) : (
                        <>
                          {" "}
                          <div style={{ fontSize: "40px" }}>
                            {" "}
                            <ImageIcon color="#3598ff"></ImageIcon>
                          </div>
                          <h4 style={{ color: "#bbb", fontWeight: "400" }}>
                            Passport
                            {/* pour les télécharger */}
                          </h4>
                        </>
                      )}{" "}
                    </div>
                  </Uploader>
                )}
                {model.customer.passportPicture ? (
                  <a
                    download={model.customer.passportPicture}
                    target="_blank"
                    href={
                      BASE_URL +
                      "Uploads/" +
                      model.customer.passportPicture +
                      "?t=" +
                      Date.now()
                    }
                  >
                    télécharger {model.customer.passportPicture}
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <Uploader
                // listType="picture"
                autoUpload={false}
                action="#"
                draggable
                fileListVisible={false}
                // onUpload={}
                onChange={(files) => {
                  APi.createAPIEndpoint(APi.ENDPOINTS.File + "/Customer")
                    .upload1(files[files.length - 1].blobFile)
                    .then((res) =>
                      setmodel((pr) => ({
                        ...pr,
                        customer: {
                          ...pr.customer,
                          picture: res.data,
                        },
                      }))
                    );

                  // setfile(files[files.length - 1].blobFile);
                  // previewFile(files[files.length - 1].blobFile, (picture) => {

                  // });
                }}
              >
                <div
                  style={{
                    background: "rgba(200,200,200,0.1)",
                    width: "130px",
                    height: "150px",
                    padding: "0",
                    display: "inline-block",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                >
                  {model.customer && model.customer.picture ? (
                    <img
                      width="130"
                      height="150"
                      src={
                        model.customer.picture.includes("base64")
                          ? model.customer.picture
                          : BASE_URL +
                            "Uploads/CustomerPictures/" +
                            model.customer.picture +
                            "?" +
                            Date.now()
                      }
                      style={{ borderRadius: "10px" }}
                      alt=""
                    />
                  ) : (
                    <>
                      {" "}
                      <div style={{ fontSize: "40px" }}>
                        {" "}
                        <ImageIcon color="#3598ff"></ImageIcon>
                      </div>
                      <h4 style={{ color: "#bbb", fontWeight: "400" }}>
                        Image
                        {/* pour les télécharger */}
                      </h4>
                    </>
                  )}
                </div>
              </Uploader>
            </div>
            {/* <Responsive m={6} l={6} xl={6} className="p-10">
              <label>Nom Complet:</label>
              <Input
                value={model.customer.name}
                onChange={(name) => {
                  let customer = { ...model.customer };

                  customer.name = name;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive> */}
            <label>Type : </label>
            <SelectPicker
              data={CustomerType}
              block
              noSearch
              value={model.customer.customerType}
              onSelect={(customerType) => {
                setmodel((prev) => {
                  return {
                    ...prev,
                    customer: { ...prev.customer, customerType },
                  };
                });
              }}
            />{" "}
            <Responsive l={6} xl={6} className="p-10">
              <label>اللقب بالعربي </label>
              <Input
                onChange={(lastNameAr) => {
                  let customer = { ...model.customer };
                  customer.lastNameAr = lastNameAr;

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.lastNameAr}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>الإسم بالعربي </label>
              <Input
                onChange={(firstNameAr) => {
                  let customer = { ...model.customer };
                  customer.firstNameAr = firstNameAr;

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.firstNameAr}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>Nom :</label>
              <Input
                onChange={(lastName) => {
                  let customer = { ...model.customer, lastName };
                  customer.name = customer.firstName + " " + lastName;
                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.lastName}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>Prénom:</label>
              <Input
                onChange={(firstName) => {
                  let customer = { ...model.customer, firstName };
                  customer.name = firstName + " " + customer.lastName;
                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.firstName}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>Nom Du Pére :</label>
              <Input
                onChange={(fatherName) => {
                  let customer = { ...model.customer, fatherName };

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.fatherName}
              />
            </Responsive>
            <Responsive l={6} xl={6} className="p-10">
              <label>CIN:</label>
              <Input
                onChange={(cin) => {
                  let customer = { ...model.customer, cin };

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.cin}
              />
            </Responsive>
            <Responsive m={3} l={3} xl={3} className="p-10">
              <label>Tél:</label>
              <Input
                type="phone"
                value={model.customer.phoneNumber}
                onChange={(phoneNumber) => {
                  let customer = { ...model.customer, phoneNumber };

                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={3} l={3} xl={3} className="p-10">
              <label>Email:</label>
              <Input
                type="email"
                value={model.customer.email}
                onChange={(email) => {
                  let customer = { ...model.customer, email };

                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            {/* <Responsive m={6} l={5} xl={5} className="p-10">
              <label>N° Passport:</label>
              <Input
                value={model.customer.passportNumber}
                onChange={(passportNumber) => {
                  let customer = { ...model.customer };

                  customer.passportNumber = passportNumber;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive> */}
            <Responsive s={6} m={3} l={3} xl={3} className="p-10">
              <label>Type : </label>
              <SelectPicker
                data={Titles}
                block
                noSearch
                value={model.customer.title}
                onChange={(title) => {
                  let customer = { ...model.customer, title };

                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={3} l={3} xl={3} className="p-10">
              <label>Genre : </label>
              <SelectPicker
                data={Gender}
                block
                noSearch
                value={model.customer.gender}
                onChange={(gender) => {
                  let customer = { ...model.customer };

                  customer.gender = gender;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Lieu De Naissance:</label>
              <Input
                onChange={(birthCity) => {
                  let customer = { ...model.customer, birthCity };

                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.birthCity}
              />
            </Responsive>
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Pays D'Issuance : </label>
              <SelectPicker
                data={countries.map((el) => ({
                  label: el.country,
                  value: el.code,
                }))}
                block
                noSearch
                value={model.customer.birthCountry}
                onChange={(birthCountry) => {
                  let customer = { ...model.customer };

                  customer.birthCountry = birthCountry;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Date de naissance :</label>
              <Input
                type="date"
                value={
                  typeof model.customer.birthDate == "string"
                    ? model.customer.birthDate.substring(0, 10)
                    : model.customer.birthDate
                }
                onChange={(birthDate) => {
                  let customer = { ...model.customer };

                  customer.birthDate = birthDate;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Lieu De Résidance:</label>
              <Input
                onChange={(city) => {
                  let customer = { ...model.customer };

                  customer.city = city;
                  setmodel((prev) => {
                    return { ...prev, customer };
                  });
                }}
                value={model.customer.city}
              />
            </Responsive>{" "}
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Pays De Sejour: </label>
              <SelectPicker
                data={countries.map((el) => ({
                  label: el.country,
                  value: el.code,
                }))}
                block
                noSearch
                value={model.customer.addressCountry}
                onChange={(addressCountry) => {
                  let customer = { ...model.customer };

                  customer.addressCountry = addressCountry;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Nationalité: </label>
              <SelectPicker
                data={countries.map((el) => ({
                  label: el.country,
                  value: el.code,
                }))}
                block
                noSearch
                value={model.customer.nationality}
                onChange={(nationality) => {
                  let customer = { ...model.customer };

                  customer.nationality = nationality;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <hr></hr>
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Passport:</label>
              <Input
                onChange={(passportNumber) => {
                  setmodel((prev) => {
                    return {
                      ...prev,
                      customer: { ...prev.customer, passportNumber },
                    };
                  });
                }}
                value={model.customer.passportNumber}
              />
            </Responsive>{" "}
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Pays D'Issuance : </label>
              <SelectPicker
                data={countries.map((el) => ({
                  label: el.country,
                  value: el.code,
                }))}
                block
                noSearch
                value={model.customer.passportDeliveryCountry}
                onChange={(passportDeliveryCountry) => {
                  let customer = { ...model.customer };

                  customer.passportDeliveryCountry = passportDeliveryCountry;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Ville D'Issuance :</label>
              <Input
                onChange={(passportDeliveryCity) => {
                  setmodel((prev) => {
                    return {
                      ...prev,
                      customer: { ...prev.customer, passportDeliveryCity },
                    };
                  });
                }}
                block
                value={model.customer.passportDeliveryCity}
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label>Passport D'Issuance :</label>
              <Input
                type="date"
                onChange={(deliveryPassportDate) => {
                  setmodel((prev) => {
                    return {
                      ...prev,
                      customer: { ...prev.customer, deliveryPassportDate },
                    };
                  });
                }}
                block
                value={
                  typeof model.customer.expirationPassportDate == "string" &&
                  model.customer.expirationPassportDate.length > 10
                    ? model.customer.expirationPassportDate.substring(0, 10)
                    : model.customer.deliveryPassportDate
                    ? model.customer.deliveryPassportDate.split("-")[0]
                        .length == 2
                      ? model.customer.deliveryPassportDate
                          .split("-")
                          .reverse()
                          .join("-")
                      : model.customer.deliveryPassportDate
                    : ""
                }
              />
            </Responsive>
            <Responsive s={6} m={4} l={4} xl={4} className="p-10">
              <label style={{ color: validatePassport ? "red" : "" }}>
                Date d'expiration :
              </label>
              <Input
                type="date"
                value={
                  typeof model.customer.expirationPassportDate == "string"
                    ? model.customer.expirationPassportDate.substring(0, 10)
                    : model.customer.expirationPassportDate
                }
                onChange={(expirationPassportDate) => {
                  let customer = { ...model.customer };

                  customer.expirationPassportDate = expirationPassportDate;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <label>MRZ</label>
            <Input
              as="textarea"
              rows={3}
              onChange={(mrz) => {
                setmodel((prev) => {
                  return { ...prev, customer: { ...prev.customer, mrz } };
                });
              }}
              value={model.customer.mrz}
            />
            <Responsive m={6} l={4} xl={4} className="p-10">
              <label>Status : </label>
              <SelectPicker
                data={MaritalStatus}
                noSearch
                block
                value={model.customer.status}
                onChange={(status) => {
                  let customer = { ...model.customer };

                  customer.status = status;
                  setmodel({ ...model, customer });
                }}
              />
            </Responsive>
            <Responsive m={4} l={4} xl={4} className="p-10">
              <label>Métier:</label>
              <Input
                onChange={(job) => {
                  setmodel((prev) => {
                    return {
                      ...prev,
                      customer: { ...prev.customer, job },
                    };
                  });
                }}
                value={model.customer.job}
              />
            </Responsive>
          </>
          <label
            style={{ color: "#991111", fontWeight: "bold", fontSize: "18px" }}
          >
            N'est pas confirmé:{" "}
            <input
              type="checkbox"
              // onClick={(e) => {
              //   _setmodel((prev) => ({
              //     ...prev,
              //     confirmationStatus: prev.confirmationStatus===2 ? 1 : 2,
              //   }));
              // }}
              onChange={(e) => {
                setmodel((prev) => ({
                  ...prev,
                  confirmationStatus: prev.confirmationStatus === 2 ? 1 : 2,
                }));
              }}
              checked={model.confirmationStatus === 2}
            ></input>
          </label>

          <br></br>
          <label>Note: </label>
          <Input
            as="textarea"
            value={model.note}
            onChange={(note) => {
              console.log(note);
              setmodel((prev) => {
                return { ...prev, note };
              });
            }}
            // onSelect={(date) =>
            //   _setmodel((prev) => {
            //     return { ...prev, date };
            //   })
            // }
            block
          />
          <br></br>
          <label>
            empreinte digitale <br></br>{" "}
            <input
              type="checkbox"
              // onClick={(e) => {
              //   _setmodel((prev) => ({
              //     ...prev,
              //     confirmationStatus: prev.confirmationStatus===2 ? 1 : 2,
              //   }));
              // }}
              onChange={(e) => {
                setmodel((prev) => ({
                  ...prev,
                  fingerprint: !prev.fingerprint,
                }));
              }}
              checked={model.fingerprint}
            ></input>
          </label>
          <div>
            Le pelerin a un code coupon?
            <div style={{ display: "flex", alignItems: "center" }}>
              <Input
                style={{ flex: 1, marginRight: "5px" }}
                value={verify}
                onChange={(v) =>
                  v.length <= 8 ? setverify(v.toUpperCase()) : {}
                }
              />
              <Button onClick={check} appearance="primary" color="cyan">
                Vérifier
              </Button>
            </div>
          </div>
          {verifyRs && <div style={{ color: "red" }}>{verifyRs}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={save} appearance="primary">
            {loading ? <Loader size="sm" /> : "Enregistrer"}
          </Button>
          <Button
            onClick={() => {
              setopen(false);
            }}
            appearance="subtle"
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
