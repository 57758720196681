import React, { useEffect, useState } from "react";
import Filter from "../../Components/Common/Filter";
import Responsive from "../../Components/Responsive";
import { DatePicker, Input, InputPicker, Pagination } from "rsuite";
import ExportAdd from "../../Components/Common/ExportAdd";
import AddEdit from "./AddEdit.component";
import { APi } from "../../Api";
import Grid from "../../Components/Grid";
import { PassagerModel } from "../../Models/PassagerModel";
import Swal from "sweetalert2";
import { useRecoilState } from "recoil";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import moment from "moment";

const Passenger = () => {
  const [filterModel, setfilterModel] = useState({
    q: "",
    date: "",
    clientId: 0,
    page: 1,
    take: 20,
  });
  const [Passengers, setPassengers] = useState([]);
  const [model, setmodel] = useState(new PassagerModel());
  const [state, setstate] = useRecoilState(exportAddAtom);
  const [totalCount, settotalCount] = useState(0);
  const reset = () => {
    setstate((prev) => ({ ...prev, open: false }));
    setmodel(new PassagerModel());
  };

  const getPassengers = () => {
    APi.createAPIEndpoint("Passenger", filterModel)
      .fetch()
      .then((res) => {
        setPassengers(res.data.data);
        settotalCount(res.data.count);
      })
      .catch((err) => console.log(err));
  };
  const save = () => {
    if (!model.id)
      APi.createAPIEndpoint("Passenger")
        .create(model)
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          getPassengers();
        })
        .catch((err) => console.log(err));
    else
      APi.createAPIEndpoint("Passenger")
        .update(model.id, model)
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          getPassengers();
        })
        .catch((err) => console.log(err));
    reset();
  };
  const getById = (id) => {
    APi.createAPIEndpoint("Passenger")
      .fetchById(id)
      .then((res) => setmodel(res.data))
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = (id) => {
    APi.createAPIEndpoint("Passenger")
      .delete(id)
      .then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        getPassengers();
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => getPassengers(), []);
  useEffect(() => console.log(filterModel), [filterModel]);
  return (
    <div>
      <Filter search={getPassengers}>
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label style={{ fontWeight: "bolder" }}>Nom ou Num Téléphone :</label>
          <Input
            value={filterModel.q}
            onChange={(q) => setfilterModel((prev) => ({ ...prev, q }))}
          />
        </Responsive>
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label style={{ fontWeight: "bolder" }}>Date:</label>
          <DatePicker
            block
            value={filterModel.date}
            onChange={(date) => setfilterModel((prev) => ({ ...prev, date }))}
          />
        </Responsive>
        {/* <Responsive m={6} l={4} xl={4} className="p-10">
          <label htmlFor="">Agence</label>
          <InputPicker
            data={clients ? clients : []}
            value={filterModel.clientId}
            onChange={(clientId) =>
              setfilterModel((prev) => ({ ...prev, clientId }))
            }
            block
          />
        </Responsive> */}
      </Filter>
      <ExportAdd
        addTitle="Ajouter Passager"
        noExport
        save={save}
        ActionOnClose={reset}
        AddComponent={<AddEdit model={model} _setmodel={setmodel} />}
      />
      <Grid
        actionKey="id"
        noAdvancedActions
        editAction={(id) => {
          getById(id);
          setstate((prev) => ({ ...prev, open: true }));
        }}
        deleteAction={(id) => handleDelete(id)}
        columns={columns}
        rows={Passengers}
      />

      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          // first
          // last
          ellipsis
          // boundaryLinks
          maxButtons={5}
          size="sm"
          layout={["total", "-", "limit", "pager"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
};

export default Passenger;
const columns = [
  {
    name: "Nom",
    value: "name",
    render: (v) => (
      <div style={{ fontSize: 18, fontWeight: 300, color: "#6a89e3" }}>{v}</div>
    ),
  },
  {
    name: "Date",
    value: "date",
    render: (v) => (
      <div style={{ fontWeight: "normal", fontSize: 17 }}>
        {moment(v).format("DD/MM/YYYY")}
      </div>
    ),
  },
  {
    name: "Téléphone",
    value: "phoneNumber",
    render: (v) => <div style={{ fontSize: 17 }}> {v}</div>,
  },
  {
    name: "Raison",
    value: "reason",
    render: (v) => (
      <div style={{ color: "white", fontSize: 17 }}> {Raison(v)}</div>
    ),
  },
];
const Raison = (v) => {
  if (v == 1)
    return (
      <span style={{ background: "#2ecc71", padding: 3, borderRadius: 5 }}>
        Prix
      </span>
    );
  if (v == 2)
    return (
      <span style={{ background: "#EA2027", padding: 3, borderRadius: 5 }}>
        Qualité
      </span>
    );
};
