import { useEffect, useState } from "react";
import { MdOutlineFoodBank } from "react-icons/md";
import { Checkbox } from "rsuite";
import format_number from "../../Helpers/number_formatter";

export const PriceOverviewItem3 = ({
  pack,
  ident,
  model,
  _setmodel,
  multiple = false,
  onChoose,
}) => {
  const [data, setdata] = useState(null);
  const [selectedMeal, setselectedMeal] = useState([]);
  const [visa, setvisa] = useState(0);
  const [visaSelected, setvisaSelected] = useState(null);

  const groupped_by = (arr) => {
    arr = arr
      .filter((el) => !el.isHidden)
      .map((el, i) => {
        return {
          ...el,
          h: el.hotelMakkahName + " - " + el.hotelMadinahName,
          index: i,
        };
      });
    let eles = new Set(arr.map((el) => el.h));

    let res = {};
    eles.forEach((el) => {
      res[el] = arr.filter((p) => p.h === el);
    });

    setdata(res);
  };
  useEffect(() => {
    if (pack.prices) groupped_by(pack.prices);
    setvisa(
      pack.packageUmrahCosts
        ? pack.packageUmrahCosts.find((el) =>
            el.designation.toUpperCase().includes("VISA")
          ).cost *
            pack.packageUmrahCosts.find((el) =>
              el.designation.toUpperCase().includes("VISA")
            ).exchange
        : 0
    );
  }, [pack]);
  useEffect(() => {
    console.log(visa);
  }, [visa]);
  return (
    <>
      {data &&
        Object.keys(data).map((k, __i) => (
          <div
            key={__i}
            style={{
              marginBottom: "30px",
              background: "#fff",
              borderRadius: "5px",
              backgroundColor: "rgb(191,155,48,0.2)",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <span style={{ color: "rgb(191,155,48)", fontWeight: "bold" }}>
                <b
                  style={{
                    width: "36px",
                    height: "36px",
                    border: "1px solid #eee",
                    borderRadius: "50%",
                    display: "inline-block",
                    textAlign: "center",
                    boxShadow: "-11px 4px 26px -10px rgba(31,50,150,0.67)",
                  }}
                >
                  <img alt="" src="/makkah.png" style={{ width: "30px" }} />
                </b>
                {data[k][0].hotelMakkahName}
              </span>
              <span style={{ color: "rgb(191,155,48)", fontWeight: "bold" }}>
                <b
                  style={{
                    width: "36px",
                    height: "36px",
                    border: "1px solid #eee",
                    borderRadius: "50%",
                    display: "inline-block",
                    textAlign: "center",
                    boxShadow: "-11px 4px 26px -10px rgba(31,50,150,0.67)",
                  }}
                >
                  <img alt="" src="/madinah4.png" style={{ width: "28px" }} />
                </b>
                {data[k][0].hotelMadinahName}
              </span>
              <table style={{ borderRadius: "5px !important" }}>
                {data[k].map((p, i) => {
                  return (
                    <tr
                      style={{
                        padding: "5px",
                        borderRadius: "5px !important",
                        background: i % 2 ? "rgb(70,103,209,0.2)" : "",
                      }}
                    >
                      <td
                        style={{
                          padding: "5px",
                        }}
                      >
                        {/* <Tag
                              color={
                                ["red", "green", "yellow"][
                                  roomTypes.findIndex(
                                    (__el) =>
                                      __el.label===
                                      p.arrangementMakkahDesignation
                                  )
                                ]
                              }
                            > */}
                        <strong> {p.arrangementMakkahDesignation}</strong>
                        {/* </Tag> */}
                      </td>

                      <td>
                        <b style={{ color: "#232323", fontSize: "17px" }}>
                          {p.price}
                          <span
                            style={{
                              color: "#888",
                              fontSize: "11px",
                              fontWeight: "400",
                            }}
                          >
                            TND
                          </span>
                        </b>
                      </td>
                      <td style={{ textAlign: "rigth" }}>
                        <button
                          onClick={(e) => {
                            onChoose({
                              priceId: p.id,
                              totalPrice:
                                selectedMeal.reduce(
                                  (a, b) => a + b.totalPrice,
                                  0
                                ) +
                                p.price -
                                (model.withoutVisa ? visa : 0),
                            });
                          }}
                          className="btn-take"
                        >
                          Prendre
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            {!multiple && (
              <div
                style={{
                  marginTop: "5px",
                  background: "#fff",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                {pack.hotels &&
                  pack.hotels
                    .filter(
                      (h) =>
                        h.name === data[k][0].hotelMadinahName ||
                        h.name === data[k][0].hotelMakkahName
                    )
                    .map((h) => {
                      if (h.packageUmrahMealTypes)
                        return h.packageUmrahMealTypes.map((m) => (
                          <div
                            onClick={() => {
                              let meals = [...selectedMeal];
                              meals = meals.filter((el) => el.k == k);
                              if (meals.find((el) => el.id == m.id)) {
                                meals = meals.filter((el) => el.id !== m.id);
                              } else meals.push({ ...m, k });
                              console.log(
                                meals.reduce((a, b) => a + b.totalPrice, 0)
                              );
                              setselectedMeal(meals);
                              _setmodel((prev) => ({
                                ...prev,
                                packageUmrahMealTypeMakkahId: h.isMakkah
                                  ? m.id
                                  : prev.packageUmrahMealTypeMakkahId,
                                packageUmrahMealTypeMadinahId: !h.isMakkah
                                  ? m.id
                                  : prev.packageUmrahMealTypeMadinahId,
                                // totalPrice: meals.reduce(
                                //   (a, b) => a + b.totalPrice,
                                //   0
                                // ),
                              }));
                            }}
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "5px",
                              border: selectedMeal.find(
                                (el) => el.id === m.id && k === el.k
                              )
                                ? "2px solid rgb(70, 103, 209)"
                                : "1px solid #ddd",
                              borderRadius: "5px",
                              margin: "2px",
                              cursor: model && "pointer",
                            }}
                          >
                            <b
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "25px",
                                  color: "rgb(70, 103, 209)",
                                  borderRadius: "50%",
                                  border: "1px solid #eee",
                                  width: "40px",
                                  height: "40px",
                                  textAlign: "center",
                                  display: "inline-block",
                                  marginRight: "8px",
                                  background: "rgb(70, 103, 209,0.2)",
                                }}
                              >
                                <MdOutlineFoodBank />
                              </span>
                              <span>
                                {m.designation}
                                <br></br>
                                <span
                                  style={{
                                    textTransform: "uppercase",
                                    color: "#888",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  {h.name}
                                  <span style={{ fontSize: "0.7em" }}>
                                    {h.isMakkah ? "Makkah" : "Madinah"}
                                  </span>
                                </span>
                              </span>
                            </b>
                            <b
                              style={{
                                color: "#53af50",
                                fontSize: "18px",
                                margin: "3px",
                              }}
                            >
                              {m.totalPrice
                                ? format_number(m.totalPrice)
                                : format_number(
                                    h.duration * h.exchange * m.cost
                                  )}
                              <span
                                style={{
                                  color: "#888",
                                  fontSize: "11px",
                                  fontWeight: "400",
                                }}
                              >
                                TND
                              </span>
                            </b>
                          </div>
                        ));
                    })}
              </div>
            )}
            <label>
              <Checkbox
                onChange={(v) => {
                  _setmodel((prev) => ({
                    ...prev,
                    withoutVisa: !prev.withoutVisa,
                  }));
                  setvisaSelected(k + "" + ident);
                  console.log(k + "" + ident);
                }}
                checked={model.withoutVisa && visaSelected == k + "" + ident}
              />
              Sans Visa
            </label>
            <label>
              <Checkbox
                onChange={(v) => {
                  _setmodel((prev) => ({
                    ...prev,
                    withoutTicket: !prev.withoutTicket,
                  }));
                  setvisaSelected(k + "" + ident);
                  console.log(k + "" + ident);
                }}
                checked={model.withoutTicket && visaSelected == k + "" + ident}
              />
              Sans Billet
            </label>
          </div>
        ))}

      <style jsx>{`
        .btn-take {
          background: rgb(191, 155, 48);
          color: #fff;
          border-radius: 4px;
          padding: 4px 10px;
        }
        .btn-take:hover {
          opacity: 0.7;
        }
      `}</style>
    </>
  );
};
