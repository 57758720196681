import React, { useEffect } from "react";
import { DatePicker, Input, InputPicker } from "rsuite";
import Responsive from "../../Components/Responsive";
import { Reasons } from "../../Constants/types";
import moment from "moment";

const AddEdit = ({ model, _setmodel }) => {
  useEffect(
    () =>
      localStorage.getItem("auth")
        ? _setmodel((prev) => ({
            ...prev,
            clientId: JSON.parse(localStorage.getItem("auth")).clientId,
          }))
        : "",
    []
  );

  return (
    <div>
      <Responsive m={4} l={4} xl={4} className="p-10">
        <label htmlFor="">Nom: </label>
        <Input
          value={model.name}
          onChange={(name) => _setmodel((prev) => ({ ...prev, name }))}
        />
      </Responsive>
      <Responsive m={4} l={4} xl={4} className="p-10">
        <label htmlFor="">Téléphone:</label>
        <Input
          value={model.phoneNumber}
          onChange={(phoneNumber) =>
            _setmodel((prev) => ({ ...prev, phoneNumber }))
          }
        />
      </Responsive>
      <Responsive m={4} l={4} xl={4} className="p-10">
        <label htmlFor="">Date:</label>
        <Input
          type="date"
          block
          value={model.date.substr(0, 10)}
          onChange={(date) => {
            _setmodel((prev) => ({ ...prev, date }));
          }}
        />
      </Responsive>

      <Responsive m={6} l={6} xl={6} className="p-10">
        <label htmlFor="">Raison:</label>
        <InputPicker
          block
          data={Reasons}
          value={model.reason}
          onChange={(reason) =>
            _setmodel((prev) =>
              reason ? { ...prev, reason } : { ...prev, reason: 0 }
            )
          }
        />
      </Responsive>
      <Responsive>
        <label htmlFor="">Note:</label>
        <Input
          as={"textarea"}
          rows={7}
          value={model.note}
          onChange={(note) => _setmodel((prev) => ({ ...prev, note }))}
        />
      </Responsive>
    </div>
  );
};

export default AddEdit;
