import { useEffect, useState } from "react";
import { MdOutlineFoodBank } from "react-icons/md";
import { Checkbox, Tag } from "rsuite";
import { roomTypes } from "../../Constants/types";
import format_number from "../../Helpers/number_formatter";
const PriceOverviewItem2 = ({
  pack,
  model,
  _setmodel,
  prices = [],
  visa = 0,
}) => {
  const [data, setdata] = useState(null);
  const [selectedMeal, setselectedMeal] = useState([]);

  const groupped_by = (arr) => {
    arr = arr
      .filter((el) => !el.isHidden)
      .map((el, i) => {
        return {
          ...el,
          h: el.hotelMakkahName + " - " + el.hotelMadinahName,
          index: i,
        };
      });
    let eles = new Set(arr.map((el) => el.h));

    let res = {};
    eles.forEach((el) => {
      res[el] = arr.filter((p) => p.h === el);
    });

    setdata(res);
  };
  useEffect(() => {
    if (prices && prices.length) groupped_by(prices);
  }, [prices]);
  useEffect(() => {
    console.log(visa);
  }, [visa]);

  return (
    <>
      <label>
        <Checkbox
          onChange={(v) => {
            _setmodel((prev) => ({
              ...prev,
              onlyTicket: !prev.onlyTicket,
              priceId: null,
              packageUmrahMealTypeMakkahId: null,
              packageUmrahMealTypeMadinahId: null,
              totalPrice: 0,
              withoutTicket: false,
              withoutVisa: false,
            }));
          }}
          checked={
            model.onlyTicket
            //||
            // (model.prices &&
            //   model.prices.find((_el) => _el.id == p.id))
          }
        />
        Billet Seulement
      </label>
      <hr></hr>
      {data &&
        Object.keys(data).map((k, __i) => (
          <div
            key={__i}
            style={{
              marginBottom: "30px",
              background: "#fff",
              borderRadius: "5px",

              boxShadow: "-11px 4px 26px -10px rgba(31,50,150,0.67)",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <table style={{ borderRadius: "5px !important" }}>
                {data[k].map((p, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        padding: "5px",
                        borderRadius: "5px !important",
                        background: i % 2 ? "rgb(70,103,209,0.2)" : "",
                      }}
                    >
                      <td
                        style={{
                          padding: "5px",
                        }}
                      >
                        <Checkbox
                          onCheckboxClick={(v) => {
                            console.log(v);
                            if (v) {
                              setselectedMeal([]);
                              _setmodel((prev) => {
                                console.log({
                                  ...prev,
                                  priceId: p.id,
                                  onlyTicket: false,
                                  packageUmrahMealTypeMakkahId: null,
                                  packageUmrahMealTypeMadinahId: null,
                                  totalPrice: p.price,
                                  onlyTicket: false,

                                  withoutTicket: data[k].find(
                                    (el) => el.id == prev.priceId
                                  )
                                    ? prev.withoutTicket
                                    : false,
                                  withoutVisa: data[k].find(
                                    (el) => el.id == prev.priceId
                                  )
                                    ? prev.withoutVisa
                                    : false,
                                });
                                return {
                                  ...prev,
                                  priceId: p.id,
                                  packageUmrahMealTypeMakkahId: null,
                                  packageUmrahMealTypeMadinahId: null,
                                  totalPrice: p.price,
                                  withoutTicket: data[k].find(
                                    (el) => el.id == prev.priceId
                                  )
                                    ? prev.withoutTicket
                                    : false,
                                  withoutVisa: data[k].find(
                                    (el) => el.id == prev.priceId
                                  )
                                    ? prev.withoutVisa
                                    : false,
                                };
                              });
                            }
                          }}
                          checked={model.priceId === p.id}
                        />
                        {
                          <Tag
                            color={
                              ["red", "green", "yellow"][
                                roomTypes.findIndex(
                                  (__el) =>
                                    __el.label ===
                                    p.arrangementMakkahDesignation
                                )
                              ]
                            }
                          >
                            {p.arrangementMakkahDesignation}
                          </Tag>
                        }
                      </td>
                      <td>
                        <img
                          alt=""
                          src="/makkah.png"
                          style={{ width: "40px" }}
                        />
                        {p.hotelMakkahName}
                      </td>
                      <td>
                        <img
                          alt=""
                          src="/madinah4.png"
                          style={{ width: "40px" }}
                        />
                        {p.hotelMadinahName}
                      </td>
                      <td>
                        <b style={{ color: "#232323", fontSize: "17px" }}>
                          {p.price}
                          <span
                            style={{
                              color: "#888",
                              fontSize: "11px",
                              fontWeight: "400",
                            }}
                          >
                            TND
                          </span>
                        </b>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <div
              style={{
                marginTop: "5px",
                background: "#fff",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              {pack.hotels &&
                pack.hotels
                  .filter(
                    (h) =>
                      h.name === data[k][0].hotelMadinahName ||
                      h.name === data[k][0].hotelMakkahName
                  )
                  .map((h) => {
                    if (h.packageUmrahMealTypes)
                      return h.packageUmrahMealTypes.map((m) => (
                        <div
                          key={m.id}
                          onClick={() => {
                            let meals = [];
                            if (model.packageUmrahMealTypeMadinahId) {
                              pack.hotels
                                .filter(
                                  (h) => h.name == data[k][0].hotelMadinahName
                                )
                                .map((_h) =>
                                  meals.push({
                                    ..._h.packageUmrahMealTypes.find(
                                      (el) =>
                                        el.id ===
                                        model.packageUmrahMealTypeMadinahId
                                    ),
                                    k,
                                  })
                                );
                            }
                            if (model.packageUmrahMealTypeMakkahId) {
                              pack.hotels
                                .filter(
                                  (h) => h.name == data[k][0].hotelMakkahName
                                )
                                .map((_h) =>
                                  meals.push({
                                    ..._h.packageUmrahMealTypes.find(
                                      (el) =>
                                        el.id ===
                                        model.packageUmrahMealTypeMakkahId
                                    ),
                                    k,
                                  })
                                );
                            }

                            //   meals = meals.filter((el) => el.k == k);
                            if (meals.find((el) => el.id == m.id)) {
                              meals = meals.filter((el) => el.id !== m.id);
                            } else meals.push({ ...m, k });

                            if (!data[k].find((el) => el.id == model.priceId))
                              meals = [];

                            setselectedMeal(meals);
                            _setmodel((prev) => {
                              return {
                                ...prev,
                                packageUmrahMealTypeMakkahId: h.isMakkah
                                  ? meals.find(
                                      (el) => el.id == m.id && el.k == k
                                    )
                                    ? m.id
                                    : null
                                  : prev.packageUmrahMealTypeMakkahId,
                                packageUmrahMealTypeMadinahId: !h.isMakkah
                                  ? meals.find(
                                      (el) => el.id == m.id && el.k == k
                                    )
                                    ? m.id
                                    : null
                                  : prev.packageUmrahMealTypeMadinahId,
                                totalPrice:
                                  (data[k].find((el) => el.id == prev.priceId)
                                    ? data[k].find(
                                        (el) => el.id == prev.priceId
                                      ).price
                                    : 0) +
                                  meals.reduce((a, b) => a + b.totalPrice, 0),
                              };
                            });
                          }}
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "5px",
                            border:
                              selectedMeal.find(
                                (el) => el.id === m.id && k === el.k
                              ) ||
                              (data[k].find((el) => el.id == model.priceId) &&
                                (model.packageUmrahMealTypeMakkahId == m.id ||
                                  model.packageUmrahMealTypeMadinahId == m.id))
                                ? "2px solid rgb(70, 103, 209)"
                                : "1px solid #ddd",
                            borderRadius: "5px",
                            margin: "2px",
                            cursor: model && "pointer",
                          }}
                        >
                          <b
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "25px",
                                color: "rgb(70, 103, 209)",
                                borderRadius: "50%",
                                border: "1px solid #eee",
                                width: "40px",
                                height: "40px",
                                textAlign: "center",
                                display: "inline-block",
                                marginRight: "8px",
                                background: "rgb(70, 103, 209,0.2)",
                              }}
                            >
                              <MdOutlineFoodBank />
                            </span>
                            <span>
                              {m.designation}
                              <br></br>
                              <span
                                style={{
                                  textTransform: "uppercase",
                                  color: "#888",
                                  fontWeight: "lighter",
                                }}
                              >
                                {h.name}
                                <span style={{ fontSize: "0.7em" }}>
                                  {h.isMakkah ? "Makkah" : "Madinah"}
                                </span>
                              </span>
                            </span>
                          </b>
                          <b
                            style={{
                              color: "#53af50",
                              fontSize: "18px",
                              margin: "3px",
                            }}
                          >
                            {m.totalPrice
                              ? format_number(m.totalPrice)
                              : format_number(h.duration * h.exchange * m.cost)}
                            <span
                              style={{
                                color: "#888",
                                fontSize: "11px",
                                fontWeight: "400",
                              }}
                            >
                              TND
                            </span>
                          </b>
                        </div>
                      ));
                  })}
              <hr />
              <label>
                <Checkbox
                  onChange={(v) => {
                    _setmodel((prev) => ({
                      ...prev,
                      withoutVisa: !prev.withoutVisa,
                      totalPrice:
                        prev.totalPrice - (!prev.withoutVisa ? visa : 0),
                    }));
                  }}
                  checked={
                    model.withoutVisa &&
                    data[k].find((el) => el.id == model.priceId)
                  }
                />
                Sans Visa
                {model.withoutVisa &&
                  data[k].find((el) => el.id == model.priceId) && (
                    <b
                      style={{
                        color: "#53af50",
                        fontSize: "18px",
                        margin: "3px",
                      }}
                    >
                      {format_number(model.totalPrice)}
                      <span
                        style={{
                          color: "#888",
                          fontSize: "11px",
                          fontWeight: "400",
                        }}
                      >
                        TND
                      </span>
                    </b>
                  )}
              </label>
              <label>
                <Checkbox
                  onChange={(v) => {
                    _setmodel((prev) => ({
                      ...prev,
                      withoutTicket: !prev.withoutTicket,
                    }));
                  }}
                  checked={
                    model.withoutTicket &&
                    data[k].find((el) => el.id == model.priceId)
                  }
                />
                Sans Billet
              </label>
            </div>
          </div>
        ))}
    </>
  );
};
export default PriceOverviewItem2;
