import ImageIcon from "@rsuite/icons/Image";
import moment from "moment";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button, Checkbox, Input, SelectPicker } from "rsuite";
import { APi } from "../../Api";
import { ENDPOINTS } from "../../Api/enpoints";
import { clientIdAtom } from "../../Atoms/clientId";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import { BASE_URL } from "../../Config/api.config";
import im1 from "./1.jpeg";
import im2 from "./2.jpeg";
import im3 from "./3.jpeg";
const Badges = ({}) => {
  const [pilgrims, setpilgrims] = useState([]);
  const [checkeds, setcheckeds] = useState([]);
  const [vols, setvols] = useState([]);

  const [printmodel, setprintmodel] = useState({
    hotelMakkah: "",
    hotelMadinah: "",
    guide: "",
    phone: "",
  });
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 1000,
    clientId: 0,
    status: 0,
    date: null,
    tagId: 0,
    forCentral: false,
  });
  const [clientId, setclientId] = useRecoilState(clientIdAtom);
  const [totalCount, settotalCount] = useState(0);

  const fetch = () => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/Umrah", {
      ...filterModel,
      date: filterModel.date
        ? moment(filterModel.date).format("DD MMM YYYY")
        : null,
    })
      .fetchAll()
      .then((res) => {
        setpilgrims(res.data.data);

        settotalCount(res.data.totalCount);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    let c = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth")).clientId
      : 0;
    setclientId(c);
    setcheckeds([]);
  }, []);
  useEffect(() => {
    let c = localStorage.getItem("auth")
      ? JSON.parse(localStorage.getItem("auth")).clientId
      : 0;
    setclientId(c);
    APi.createAPIEndpoint(ENDPOINTS.PackageUmrah + "/getForPrices", {
      page: 1,
      take: 200,
    })
      .customGet()
      .then((res) => {
        let _vols = res.data.data.reduce(
          (prec, currentItem) => [...prec, ...currentItem.disponibilities],
          []
        );
        console.log(_vols);
        setvols(_vols);
      });
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  const _print = async () => {
    let els = pilgrims.filter((p1) => checkeds.find((_p) => _p == p1.id));
    // make here the print
  };
  const columns = [
    {
      value: "customer",
      value2: "id",
      name: " Nom",
      render: (v, id) => (
        <b style={{ color: "green", display: "flex", alignItems: "center" }}>
          <Checkbox
            onChange={(v) => {
              if (checkeds.find((el) => el == id))
                setcheckeds((prev) => prev.filter((l) => l != id));
              else setcheckeds((prev) => [...prev, id]);
            }}
            checked={checkeds.find((el) => el == id) != null}
          ></Checkbox>
          <a style={{ display: "flex", alignItems: "center" }}>
            {v.picture ? (
              <img
                alt=""
                src={BASE_URL + "Uploads/CustomerPictures/" + v.picture}
                width="43"
                height="43"
                style={{
                  border: "1px solid #eee",
                  borderRadius: "50%",
                  boxShadow: "-11px -5px 20px -13px rgba(0,0,0,0.75)",
                }}
              />
            ) : (
              <div
                style={{
                  fontSize: "15px",
                  border: "1px dashed #888",
                  borderRadius: "50%",
                  width: "43px",
                  height: "43px",
                  textAlign: "center",
                  lineHeight: "42px",
                }}
              >
                <ImageIcon color="#3598ff"></ImageIcon>
              </div>
            )}
            <div style={{ padding: "0 5px" }}>
              {" "}
              {v.firstName + "  " + v.lastName}
              {"  "}
              <i
                style={{
                  color: "#999",
                  fontSize: "14px",
                  fontWeight: "lighter",
                }}
              >
                {v ? v.passportNumber : ""}
              </i>
              <br></br>{" "}
              <i style={{ color: "#444" }}>
                {v.firstNameAr + "  " + v.lastNameAr}
              </i>
            </div>
          </a>
        </b>
      ),
    },
  ];
  const handlePrint = () => {
    const iframe = document.getElementById("iframe");
    // let frameDoc = iframe.contentDocument || iframe.contentWindow.document;
    // frameDoc.removeChild(frameDoc.documentElement);
    iframe.contentDocument.body.innerHTML = "";

    const content = generateHTMLContent();
    iframe.contentDocument.write(content);
    iframe.contentWindow.print();
  };
  function generateHTMLContentDelice() {
    // Replace this with your dynamic content generation logic
    let ppp = [
      [
        " H297783",
        //   "j115103",
        //   "i833365",
        //   "N268008",
        //   "N268007",
        //   "I945793",
        //   "J119350",
        //   "N384377",
        //   "N354773",
        //   "309721028",
        //   "307209250",
        //   "177654682",
        //   "I833500",
        //   "I945873",
        //   "H740914",
        //   "j326521",
        //   "J961488",
        //   "N266165",
        //   "n268121",
        //   "I223432",
        //   "J952928",
        //   "J856131",
        //   "n273136",
        //   "I895763",
        //   "n211199",
        //   "n275319",
        //   "H746701",
        //   "n311167",
        //   "N340790",
        //   "h798974",
        //   "n278387",
        //   "n272960",
        //   "h871527",
        //   "J856421",
        //   "n275208",
        //   "N314002",
        //   "N273164",
        //   "N314087",
        //   "J517765",
        //   "I672589",
        //   "J115016",
      ],
      [
        "OW3051706",

        // "I142998",
        // "J730502",
        // "n283143",
        // "H694742",
        // "J953063",
        // "J953052",
        // "J028088",
        // "J127790",
        // "n268079",
        // "n268120",
        // "n152322",
        // "i498228",
        // "J985573",
        // "n273082",
        // "n275535",
        // "j333052",
        // "J904337",
        // "n268190",
        // "n273293",
        // "N062187",
        // "J081025",
        // "J923854",
        // "j661688",
        // "N268119",
        // "H091983",
        // "j650922",
        // "I833402",
        // "H205337",
        // "j661582",
        // "j861520",
        // "j650618",
        // "j352173",
        // "h600698",
        // "j661689",
        // "j661690",
        // "n268122",
        // "n273187",
        // "i776827",
        // "J993489",
      ],
      [
        // "H926391",
        // "I005404",
        // "I886908",
        // "J192019",
        // "n273083",
        // "n275892",
        // "n268150",
        // "n284678",
        // "h227439",
        // "j560948",
        // "n313972",
        // "n268141",
        // "j045087",
        // "N034077",
        // "n068471",
        // "j853509",
        // "j780043",
        // "J959895",
        // "J857055",
        // "J884640",
        // "J885627",
        // "J997177",
        // "N349125",
        // "N403804",
        // "N403803",
        // "I634422",
        // "J922480",
        // "J895766",
        // "N268058",
        // "n321655",
        // "i895946",
        // "n812023",
        // "n284679",
        // "j334896",
        // "J045086",
        // "J045242",
        // "I896441",
        // "I895927",
        // "H930134",
        // "i793395",
        // "h046933",
        "J812023",
      ],
    ];
    let els = pilgrims.filter((p1) => {
      return (
        ppp[0].find(
          (_p) =>
            _p &&
            _p.trim().toUpperCase() ==
              p1.customer.passportNumber.trim().toUpperCase()
        ) || false
      );
    });
    console.log(els);
    // Generate a list of image tags dynamically
    const imageTags = els
      .map((el) => el.customer)
      .map(
        (v, index) =>
          `  <div style="display:inline-block; width:3.9in;
          height:5.65in;  -webkit-print-color-adjust: exact; 
border-radius:10px; vertical-align:top ; margin:5px;
          ;position:relative;" >
 <img style="width:3.9in; height:5.65in; display:block"
          alt=""
          src=${im1}          />


<div style="position:absolute;top:0; left:0;width:3.9in; height:5.65in; display:block"> 
<img style="width:90px; height:100px; 
         position: relative;
        margin-top: 77px; left:50%; transform:translate(-50%)"
          alt=""
          src=${BASE_URL + "Uploads/CustomerPictures/" + v.picture}          />
                    
          
          <div style="text-align:right;position:absolute; right:110px; top:185px; color:#232323;font-size:20px;">  ${
            v.firstNameAr
          }</div>
           <div style="text-align:right;position:absolute; right:110px; top:210px; color:#232323;font-size:20px;">  ${
             v.lastNameAr
           }</div>
            <div style="text-align:right;position:absolute; right:30px; top:260px; color:#232323;font-size:16px;">  ${
              v.passportNumber
            }</div>

                <div style="text-align:right;position:absolute; right:110px; top:315px; color:#232323;font-size:15px;">  ${"ايلاف المشاعر برستيج"}</div>
                <div style="text-align:right;position:absolute; right:110px; top:363px; color:#232323;font-size:15px;">  ${"فيو المدينة "}</div>
                  <div style="text-align:right;position:absolute; right:80px; top:412px; color:#232323;font-size:15px;">  ${"بشير الزاوش 98107051"}</div>
                    <div style="text-align:right;position:absolute; right:122px; top:456px; color:#232323;font-size:15px;"> مفيدة الشابي - شاكر عادل كشك</div>
                    <div style="text-align:right;position:absolute; right:160px; top:478px; color:#232323;font-size:15px;text-align:center"> بوبكر العرفاوي</div>
          </div>
          </div>`
      )
      .join("");
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    // The complete HTML document structure
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Badges v_${Date.now()}</title>
      </head>
      <body>
 ${imageTags}

      </body>
      </html>
    `;
  }
  function generateHTMLContent() {
    // Replace this with your dynamic content generation logic

    let els = pilgrims.filter((p1) => checkeds.find((_p) => _p == p1.id));

    // Generate a list of image tags dynamically
    const imageTags = els
      .map((el) => el.customer)
      .map(
        (v, index) =>
          `  <div style="display:inline-block; width:3.9in;
          height:5.65in;background-color: #d0e6f5;  -webkit-print-color-adjust: exact; 
border-radius:10px; vertical-align:top ; margin:5px
          ;" >
          <div style="border:1px dashed #57aee7; border-top-right-radius: 10px; border-top-left-radius: 10px; 
          border-bottom:10px solid #57aee7; background-color: #ffffff; padding:10px;padding-bottom:50px; margin-bottom:10px">   
          <img alt="" class="logo" style="width:2in;" src="https://www.kounouz.travel/images/logo.svg"
        />
        <img style="width:50px; height:30px;position: relative;margin-top: -40px;   margin-left:80px;" class="flag" src="https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg"/></div>
       
        <img style="width:100px; height: 100px; border:10px solid #57aee7;
        border-radius: 50%;
        box-shadow: -11px -5px 20px -13px rgba(0,0,0,0.75); position: relative;
        margin-top: -75px; margin-left:50px"
          alt=""
          src=${BASE_URL + "Uploads/CustomerPictures/" + v.picture}          />
          <div class="infos" style="text-align:right;   background-color: #ffffff; padding:5px; border-radius:10px;
          width:90%; margin: 0 auto;
          " >
          <div style="text-align:right; color:#232323;font-size:24px; margin-bottom:5px;"> <span style=" color:#4667d1; font-size:24px; margin-bottom:5px" >الإسم </span> : ${
            v.firstNameAr + " " + v.lastNameAr
          }</div>

          <div style="text-align:right; color:#232323;font-size:24px; margin-bottom:5px;">${
            v.passportNumber
          }<span style=" color:#4667d1; font-size:24px; margin-bottom:5px">:رقم الجواز  </span>  </div> 

          <div style="text-align:right; color:#232323;font-size:24px; margin-bottom:5px;"><span style=" color:#4667d1; font-size:24px; margin-bottom:5px" >فندق مكة</span> 
          ${printmodel.hotelMakkah}  </div>
          <div style="text-align:right; color:#232323;font-size:24px; margin-bottom:5px;" ><span style=" color:#4667d1; font-size:24px; margin-bottom:5px" > فندق مدينة
          </span>
      ${printmodel.hotelMadinah}  </div>
       
          <div style="text-align:right; color:#232323;font-size:24px; margin-bottom:5px;" ><span style=" color:#4667d1; font-size:24px; margin-bottom:5px" > إسم المرافق</span> 
          ${printmodel.guide}   </div>
          </div>
          <div class="infos" style="text-align:right;  background-color: #57aee7; padding:5px; border-radius:20px;text-align: center;
          width:90%; margin: 0 auto;margin-top: 
          5px; font-weight: 800; color:white
          " >
          ${printmodel.phone}
        </div>

        </div>`
      )
      .join("");
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    //   <div rtl="true"><span style="color:#4667d1; font-size:22px">: مندوب  مدينة  <span></div>
    // The complete HTML document structure
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Badges v_${Date.now()}</title>
      </head>
      <body>
 ${imageTags}
      </body>
      </html>
    `;
  }
  return (
    <div>
      <Filter search={() => fetch()}>
        <Responsive className="p-10">
          <label>Vols: </label>

          <SelectPicker
            data={[{ label: "Tout", value: 0 }].concat(
              vols
                ? vols.map((el, i) => {
                    return {
                      label: (
                        <div
                          style={{
                            color: "#232323",
                            background: i % 2 ? "rgba(11,150,102,0.2)" : "",
                          }}
                        >
                          {moment(el.date).format("DD MMM YYYY")}
                        </div>
                      ),
                      value: el.id,
                    };
                  })
                : []
            )}
            block
            noSearch
            value={filterModel.disponibilityId}
            onSelect={(disponibilityId) => {
              setfilterModel((prev) => {
                return { ...prev, disponibilityId };
              });
            }}
          />
        </Responsive>
      </Filter>
      <div style={{ overflow: "hidden", height: 0 }}>
        <iframe id="iframe" title="printFrame" width="100%"></iframe>
      </div>{" "}
      <img src="logo.svg" alt="" style={{ width: 0, height: 0 }} />
      <img
        alt=""
        src="https://www.kounouz.travel/images/logo.svg"
        width="0"
        height="0"
      />
      <img
        alt=""
        src="https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg"
        width="0"
        height="0"
      />
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Hotel Makkah</label>
        <Input
          value={printmodel.hotelMakkah}
          onChange={(hotelMakkah) =>
            setprintmodel((prev) => ({ ...prev, hotelMakkah }))
          }
        ></Input>
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Hotel Madinah</label>
        <Input
          value={printmodel.hotelMadinah}
          onChange={(hotelMadinah) =>
            setprintmodel((prev) => ({ ...prev, hotelMadinah }))
          }
        ></Input>
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Téléphone</label>
        <Input
          value={printmodel.phone}
          onChange={(phone) => setprintmodel((prev) => ({ ...prev, phone }))}
        ></Input>
      </Responsive>
      <Responsive m={6} l={6} xl={6} className="p-10">
        <label>Accompagnant</label>
        <Input
          value={printmodel.guide}
          onChange={(guide) => setprintmodel((prev) => ({ ...prev, guide }))}
        ></Input>
      </Responsive>
      <div style={{ textAlign: "right", padding: "10px" }}>
        <Button appearance="primary" color="blue" onClick={handlePrint}>
          imprimer
        </Button>{" "}
      </div>
      <div
        onClick={(e) =>
          setcheckeds((prev) =>
            prev.length ? [] : pilgrims.map((el) => el.id)
          )
        }
        style={{
          display: "flex",
          alignItems: "center",

          padding: "8px",
          borderRadius: "4px",
          background: "#fff",
        }}
      >
        <Checkbox checked={checkeds.length == pilgrims.length}></Checkbox>{" "}
        Sélectionner Tout
      </div>
      <Grid columns={columns} rows={pilgrims} />
    </div>
  );
};

export default Badges;
