import moment from "moment";
import React from "react";
import { MdAddAlert } from "react-icons/md";
import { useRecoilState } from "recoil";
import { Tag } from "rsuite";
import { clientAtom } from "../../Atoms/client.atom";
import { SaleVoState } from "../../Atoms/SaleVo.atom";
import { PriceOverviewItem3 } from "./PricesView";

const PriceItem = ({ item, ident, packages, onChoose, clientId }) => {
  const [model, setmodel] = useRecoilState(SaleVoState);
  const [client, setclient] = useRecoilState(clientAtom);

  return !(packages && packages.length) ? (
    <div></div>
  ) : (
    <div
      style={{
        border: "1px solid #ddd",
        background: "#fff",
        borderRadius: "8px",
        position: "relative",
      }}
    >
      <p
        className="p-10"
        style={{
          background: "rgba(46, 204, 113,0.1)",
          borderBottom: "1px solid",
          fontWeight: "bold",
        }}
      >
        {" "}
        {item.name}{" "}
      </p>
      {item.clients.find(
        (el) =>
          el.clientId === clientId ||
          (client && el.clientId == client.belongsToClientId)
      ) &&
        item.clients.find(
          (el) =>
            el.clientId === clientId ||
            (client && el.clientId == client.belongsToClientId)
        ).nbr <=
          item.clients.find(
            (el) =>
              el.clientId === clientId ||
              (client && el.clientId == client.belongsToClientId)
          ).nbrConfirmed +
            item.clients.find(
              (el) =>
                el.clientId === clientId ||
                (client && el.clientId == client.belongsToClientId)
            ).nbrNonConfirmedByClient +
            item.clients.find(
              (el) =>
                el.clientId === clientId ||
                (client && el.clientId == client.belongsToClientId)
            ).nbrPending +
            (item.clients.find(
              (el) => el.client && el.client.name === "UMRAH PUBLIC"
            )
              ? item.clients.find(
                  (el) => el.client && el.client.name === "UMRAH PUBLIC"
                ).nbr
              : 0) &&
        (!item.clients.find(
          (el) => el.client && el.client.name === "UMRAH PUBLIC"
        ) ||
          !item.clients.find(
            (el) => el.client && el.client.name === "UMRAH PUBLIC"
          ).nbr) && (
          <div
            style={{
              position: "absolute",
              background: "rgba(0,0,0,0.1)",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              textAlign: "center",
            }}
          >
            <Tag color="red">
              <MdAddAlert /> Non Disponible
            </Tag>
          </div>
        )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "hidden",
          padding: "10px",
          background: "rgba(46, 204, 113,0.1)",
        }}
      >
        <b
          style={{
            display: "inline-flex",
            alignItems: "center",
            padding: "3px",

            borderRadius: "5px",
          }}
        >
          <img
            alt=""
            style={{ width: "30px", height: "30px", margin: "0 6px" }}
            src={
              item.disponibility.airline ? item.disponibility.airline.logo : ""
            }
          ></img>
          <b>
            <div style={{ color: "#232323" }}>
              {moment(item.disponibility.date).format("DD MMM YYYY")} (
              <i style={{ color: "#999" }}>
                {packages &&
                  packages.find((l) => l.id == item.packageId) &&
                  packages.find((l) => l.id == item.packageId).name}
              </i>
              )
            </div>
            <b style={{ color: "#888", fontWeight: "lighter" }}>
              {item.disponibility.airline
                ? item.disponibility.airline.name
                : ""}
            </b>
          </b>
        </b>

        <b
          style={{
            position: "relative",
          }}
        >
          <div
            style={{
              width: "13px",
              height: "110px",
              background: "#2ecc71",
              transform: "rotate(20deg)",
              content: "",
              position: "absolute",
              top: "-37px",
              left: "-15px",
            }}
          ></div>
          <div
            style={{
              textTransform: "uppercase",
              color: "#999",
              fontWeight: "lighter",
            }}
          >
            Date d'option
          </div>
          <div style={{ color: "#232323" }}>
            {moment(item.optionDate).format("DD MMM YYYY")}
          </div>
        </b>
      </div>
      <div className="p-10" style={{ borderBottom: "1px solid #eee" }}>
        <Tag color="black">
          Nbr Préavis :
          <b>
            {item.clients.find(
              (el) =>
                el.clientId === clientId ||
                (client && el.clientId == client.belongsToClientId)
            )
              ? item.clients.find(
                  (el) =>
                    el.clientId === clientId ||
                    (client && el.clientId == client.belongsToClientId)
                ).nbr
              : 0}
          </b>
        </Tag>
        {!item.clients.find(
          (el) =>
            el.clientId === clientId ||
            (client && el.clientId == client.belongsToClientId)
        ) ||
        !item.clients.find(
          (el) =>
            el.clientId === clientId ||
            (client && el.clientId == client.belongsToClientId)
        ).nbrConfirmed ? (
          ""
        ) : (
          <Tag color="green">
            Confirmés :
            <b>
              {
                item.clients.find(
                  (el) =>
                    el.clientId === clientId ||
                    (client && el.clientId == client.belongsToClientId)
                ).nbrConfirmed
              }
            </b>
          </Tag>
        )}
        {!item.clients.find(
          (el) =>
            el.clientId === clientId ||
            (client && el.clientId == client.belongsToClientId)
        ) ||
        !item.clients.find(
          (el) =>
            el.clientId === clientId ||
            (client && el.clientId == client.belongsToClientId)
        ).nbrNonConfirmedByClient ? (
          ""
        ) : (
          <Tag color="violet" style={{ margin: "5px" }}>
            En Attente De Votre Confirmation :
            <b>
              {
                item.clients.find(
                  (el) =>
                    el.clientId === clientId ||
                    (client && el.clientId == client.belongsToClientId)
                ).nbrNonConfirmedByClient
              }
            </b>
          </Tag>
        )}
        {!item.clients.find(
          (el) =>
            el.clientId === clientId ||
            (client && el.clientId == client.belongsToClientId)
        ) ||
        !item.clients.find(
          (el) =>
            el.clientId === clientId ||
            (client && el.clientId == client.belongsToClientId)
        ).nbrPending ? (
          ""
        ) : (
          <Tag color="yellow">
            En Attente Confirmation :
            <b>
              {
                item.clients.find(
                  (el) =>
                    el.clientId === clientId ||
                    (client && el.clientId == client.belongsToClientId)
                ).nbrPending
              }
            </b>
          </Tag>
        )}
        {!item.clients.find(
          (el) => el.client && el.client.name === "UMRAH PUBLIC"
        ) ? (
          ""
        ) : (
          <Tag color="blue">
            places publiques
            <b>
              {
                item.clients.find(
                  (el) => el.client && el.client.name === "UMRAH PUBLIC"
                ).nbr
              }
            </b>
            place(s)
          </Tag>
        )}
      </div>
      <div className="p-10">
        <PriceOverviewItem3
          ident={ident}
          pack={{
            ...packages.find((el) => el.id == item.packageId),
            prices: item.prices.map((p) => {
              let _pack = packages.find((el) => el.id == item.packageId);
              let disp = item.disponibility;
              let c = 0;
              if (disp) {
                c =
                  disp.commission +
                  disp.b2Bcommission -
                  (_pack.disponibilities[0].commission +
                    _pack.disponibilities[0].b2Bcommission);
              }
              let _p = { ...p, price: p.price + c };
              return _p;
            }),
          }}
          onChoose={onChoose}
          model={model}
          _setmodel={setmodel}
        />
      </div>
    </div>
  );
};

export default PriceItem;
