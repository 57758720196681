import TextImageIcon from "@rsuite/icons/TextImage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Input, SelectPicker } from "rsuite";
import Pagination from "rsuite/Pagination";
import Swal from "sweetalert2";
import { APi } from "../../Api/";
import { exportAddAtom } from "../../Atoms/exportAdd.atom";
import ExportAdd from "../../Components/Common/ExportAdd";
import Filter from "../../Components/Common/Filter";
import Grid from "../../Components/Grid";
import Responsive from "../../Components/Responsive";
import AddEdit from "./AddEdit.component";
import { multiDataSet } from "./excel_data";

import { BiTrip } from "react-icons/bi";
import { SaleVoState } from "../../Atoms/SaleVo.atom";
import ResumeCard from "../../Components/ResumeCard";
import SaleVoModel from "../../Models/SaleVoModel";
export default function SaleVo(props) {
  // STATE
  const [data, setdata] = useState([]);
  const [excel_data, setexcel_data] = useState([]);
  const [clients, setclients] = useState([]);
  const [clients2, setclients2] = useState([]);
  const [banks, setbanks] = useState([]);
  const [checkouts, setcheckouts] = useState([]);
  const [agentBookingFlights, setAgentBookingFlights] = useState([]);
  const [tags, setTags] = useState([]);
  const [providers, setproviders] = useState([]);
  const [packages, setpackages] = useState([]);

  const [totalCount, settotalCount] = useState(0);
  const [filterModel, setfilterModel] = useState({
    page: 1,
    take: 20,
    clientId: 0,
    status: 0,
    date: null,
    tagId: 0,
  });
  // --- add edit model ---
  const [error, setError] = useState("");
  const [model, setmodel] = useRecoilState(SaleVoState);

  // ATOMS
  const [state, setstate] = useRecoilState(exportAddAtom);
  // HELPERS
  const reset = () => {
    setmodel(new SaleVoModel());
    setError("");
  };
  // API CALLS
  const fetchClients = (q, forFilter = true) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Client, { q }, "/autocomplete")
        .customGet()
        .then((res) =>
          forFilter ? setclients(res.data) : setclients2(res.data)
        );
    }
  };
  const fetchBanks = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Bank, { q }, "/autocomplete")
        .customGet()
        .then((res) => setbanks(res.data));
    }
  };
  const fetchAgentBookingFlights = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(
        APi.ENDPOINTS.AgentBookingFlight,
        { q },
        "/autocomplete"
      )
        .customGet()
        .then((res) => setAgentBookingFlights(res.data));
    }
  };
  const fetchCheckouts = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Checkout, { q }, "/autocomplete")
        .customGet()
        .then((res) => setcheckouts(res.data));
    }
  };

  const fetchProviders = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Provider, { q }, "/autocomplete2")
        .customGet()
        .then((res) => setproviders(res.data));
    }
  };
  const fetchTags = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Tag, { q }, "/autocomplete")
        .customGet()
        .then((res) => setTags(res.data));
    }
  };
  const fetch = (page) => {
    APi.createAPIEndpoint(
      APi.ENDPOINTS.Order + "/VO",
      // +'/VO'
      {
        ...filterModel,

        date: filterModel.date
          ? moment(filterModel.date).format("DD MMM YYYY")
          : null,
        page: page ? page : filterModel.page,
      }
    )
      .fetchAll()
      .then((res) => {
        setdata(res.data.data);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
        settotalCount(res.data.totalCount);
      })
      .catch((e) => {
        setError(e.Message);
        setstate((prev) => {
          return { ...prev, loading: false };
        });
      });
  };

  const save = () => {
    // let msg = validate(model, [
    //   { number: "Numéro" },
    //   { status: "Status" },
    //   { amount: "Montant" },
    //   { depositDate: "Date de Dépôt" },
    //   // { date: "Date" },

    //   { checkPurpose: "Type de Chéque" },
    //   // { clientId: "Client" },
    // ]);
    // if (msg) setError(msg);
    // else {
    console.log(model);

    setstate((prev) => {
      return { ...prev, loading: true };
    });
    let m = {
      ...model,
    };

    console.log(m);

    if (model.id) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/VO")
        .update(m.id, m)
        .then((res) => {
          fetch();
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Élément a été bien modifié !",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    } else {
      APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/VO")
        .create(m)
        .then((res) => {
          fetch();
          reset();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Element a été bien ajouté !",
            showConfirmButton: false,
            timer: 1500,
          });
          setstate((prev) => {
            return { ...prev, open: false, loading: false };
          });
        })
        .catch((e) => {
          setError(e.Message);
          setstate((prev) => {
            return { ...prev, loading: false };
          });
        });
    }
    // }
  };
  const deleteAction = (id) => {
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/VO")
      .delete(id)

      .then((res) => {
        fetch();
        Swal.fire("Supprimé !", "", "success");
      })
      .catch((e) => setError(e.Message));
  };
  //
  const getBYId = (id) => {
    setError("");
    APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/VO")
      .fetchById(id)
      .then((res) => {
        if (
          res.data.client &&
          !clients2.find((c) => c.id == res.data.client.id)
        ) {
          let arr = [...clients2];
          arr.unshift({ id: res.data.client.id, name: res.data.client.name });
          setclients2(arr);
        }

        setmodel((prev) => ({
          ...res.data,
          package: {
            ...res.data.package,
            // packageItems: res.data.package.packageItems.map((p) => {
            //   if (p.service == 2) {
            //     p.hotelReservation.checkIn =
            //       p.hotelReservation.checkIn.substring(0, 10);
            //     p.hotelReservation.checkOut =
            //       p.hotelReservation.checkOut.substring(0, 10);
            //   }
            //   return p;
            // }),
          },
          date: res.data.date.substring(0, 10),
          customer: {
            ...res.data.customer,
            expirationPassportDate:
              res.data.customer.expirationPassportDate.substring(0, 10),
            birthDate: res.data.customer.birthDate.substring(0, 10),
          },
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchPackages = (q) => {
    if (typeof q == "undefined" || q.length > 2) {
      APi.createAPIEndpoint(APi.ENDPOINTS.Package, { q }, "/autocomplete")
        .customGet()
        .then((res) => setpackages(res.data));
    }
  };
  // LIFE CYCLES
  useEffect(() => {
    fetch();
    fetchClients();
    fetchBanks();
    fetchCheckouts();
    fetchTags();
    fetchProviders();
    fetchAgentBookingFlights();
    fetchPackages();
  }, []);
  useEffect(() => {
    fetch();
  }, [filterModel.page, filterModel.take]);
  return (
    <div>
      <Filter search={() => fetch()}>
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Période: </label>
          {/* <DatePicker
            placement="bottomStart"
            value={filterModel.date}
            onChange={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            onSelect={(date) =>
              setfilterModel((prev) => {
                return { ...prev, date };
              })
            }
            block
          /> 
        </Responsive> */}
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label>Client: </label>
          <SelectPicker
            onSearch={(q) => fetchClients(q)}
            data={[{ label: "Tout", value: 0 }].concat(
              clients.map((c) => {
                return { label: c.name, value: c.id };
              })
            )}
            block
            noSearch
            value={filterModel.clientId}
            onSelect={(clientId) => {
              setfilterModel((prev) => {
                return { ...prev, clientId };
              });
            }}
          />
        </Responsive>
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label>Voyage: </label>
          <SelectPicker
            onSearch={fetchPackages}
            data={[{ label: "Tout", value: 0 }].concat(
              packages.map((el) => {
                return { label: el.name, value: el.id };
              })
            )}
            block
            noSearch
            value={filterModel.packageId}
            onSelect={(packageId) => {
              setfilterModel((prev) => {
                return { ...prev, packageId };
              });
            }}
          />
        </Responsive>
        {/* <Responsive m={6} l={2.4} xl={2.4} className="p-10">
          <label>Status: </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(checkStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive> */}

        {/* <Responsive m={6} l={3} xl={3} className="p-10">
          <label>Status du Paiement: </label>
          <SelectPicker
            searchable={false}
            data={[{ label: "Tout", value: 0 }].concat(SaleStatus)}
            block
            noSearch
            value={filterModel.status}
            onSelect={(status) => {
              setfilterModel((prev) => {
                return { ...prev, status };
              });
            }}
          />
        </Responsive> */}
        <Responsive m={6} l={4} xl={4} className="p-10">
          <label>Date du Vente: </label>
          <Input
            type="date"
            value={filterModel.date}
            onChange={(date) => {
              setfilterModel((prev) => {
                return { ...prev, date };
              });
            }}
          />
        </Responsive>
      </Filter>
      <ExportAdd
        excelData={multiDataSet(excel_data)}
        ActionOnClose={reset}
        nameExcel="saleVo"
        size="lg"
        save={save}
        handleExport={() => {
          APi.createAPIEndpoint(APi.ENDPOINTS.Order + "/GetOrerVosForExcel", {
            ...filterModel,
            date: filterModel.date
              ? moment(filterModel.date).format("DD MMM YYYY")
              : null,
            take: totalCount + 10,
            page: 1,
          })
            .fetchAll()
            .then((res) => {
              setexcel_data(res.data.data);
            })
            .catch((e) => setError(e.Message));
        }}
        AddComponent={
          <AddEdit
            checkouts={checkouts}
            tags={tags}
            fetchCheckouts={fetchCheckouts}
            fetchTags={fetchTags}
            providers={providers}
            fetchProviders={fetchProviders}
            error={error}
            banks={banks}
            fetchBanks={fetchBanks}
            clients={clients2}
            fetchClients={(q) => fetchClients(q, false)}
            fetchAgentBookingFlights={fetchAgentBookingFlights}
            agentBookingFlights={agentBookingFlights}
          />
        }
      />
      <div>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard
            icon={<BiTrip />}
            notAmount
            text="Total Voyages"
            color="245,195,35"
            amount={totalCount}
          />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total Vente" color="0,169,141" />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total Remboursement" color="229,57,53" />
        </Responsive>
        <Responsive className="p-10" xs={6} s={6} m={6} l={3} xl={3}>
          <ResumeCard text="Total Commission" />
        </Responsive>
      </div>
      <Grid
        editAction={(id) => {
          getBYId(id);

          setstate((prev) => {
            return { ...prev, open: true };
          });
        }}
        deleteAction={deleteAction}
        actionKey="id"
        // noAdvancedActions // for custom advanced actions
        actions={[
          {
            label: "génerer facture",
            action: (dataKey) => {
              console.log(dataKey);
              // generate_invoice(dataKey);
            },
            render: (v) => (
              <button
                style={{
                  color: "rgba(67,55,160,1)",
                  padding: "6px 10px",
                  fontSize: "12px",
                  background: "rgba(67,55,160,0.1)",
                  borderRadius: "4px",
                }}
              >
                <TextImageIcon /> {"  "}
                {v}
              </button>
            ),
          },
        ]}
        columns={columns}
        rows={data}
      />
      <div style={{ padding: 20, background: "#fff" }}>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={["total", "-", "limit", "|", "pager", "skip"]}
          total={totalCount}
          limitOptions={[10, 20, 50, 100]}
          limit={filterModel.take}
          activePage={filterModel.page}
          onChangePage={(page) => {
            window.scrollTo(0, 0);
            setfilterModel((prev) => {
              return { ...prev, page };
            });
          }}
          onChangeLimit={(take) => {
            console.log(take);
            setfilterModel((prev) => {
              return { ...prev, take, page: 1 };
            });
          }}
        />
      </div>
    </div>
  );
}

const columns = [
  {
    value: "packageName",
    name: "Voyage",
    render: (v) => (
      <b style={{ color: "#549f0a" }}>{v ? v.toUpperCase() : ""}</b>
    ),
  },
  {
    value: "client",
    name: "Client B2B",
    render: (v) => <a>{v ? v.name.toUpperCase() : ""}</a>,
  },
  {
    value: "customer",
    name: "Client B2C",
    render: (v) => (
      <a>
        {v ? v.name.toUpperCase() : ""}
        <br></br> <i style={{ color: "#aaa" }}>{v ? v.passportNumber : ""}</i>
      </a>
    ),
  },

  {
    value: "totalPrice",
    value2: "currency",
    value3: "commission",
    name: "Montant à payer",
    render: (v, v2, v3) => (
      <b style={{ color: "#53af50", fontSize: "18px" }}>
        {(v - v3).toFixed(3) + " "}
        <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
          {v2}
        </span>
      </b>
    ),
  },
  {
    value2: "currency",
    value: "commission",
    name: "Commission B2B",
    render: (v, v2) => (
      <b style={{ color: "#333", fontSize: "18px" }}>
        {v.toFixed(3) + " "}
        <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
          {v2}
        </span>
      </b>
    ),
  },
  {
    value: "totalPrice",
    value2: "currency",

    name: "Prix Total",
    render: (v, v2) => (
      <b style={{ color: "#53af50", fontSize: "18px" }}>
        {v.toFixed(3) + " "}
        <span style={{ color: "#888", fontSize: "11px", fontWeight: "400" }}>
          {v2}
        </span>
      </b>
    ),
  },
  {
    value: "date",
    name: "Date",
    render: (v) => <strong>{moment(v).format("DD MMM YYYY")}</strong>,
  },
  {
    value: "notes",
    name: "Notes",
    render: (v) => {
      return (
        <div
          style={{
            maxWidth: "260px",
            padding: "10px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {v}
        </div>
      );
    },
  },
];
