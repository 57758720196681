import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  Button,
  ButtonToolbar,
  Content,
  FlexboxGrid,
  Form,
  Message,
  Panel,
} from "rsuite";
import { AuthService } from "../../Api/auth.service";
import { isLogged } from "../../Atoms/auth.atom";
import Swal from "sweetalert2";

export default function ResetPassword(props) {
  const setLogged = useSetRecoilState(isLogged);
  const [model, setmodel] = useState({ username: "", password: "" });
  const [error, seterror] = useState("");
  function authenticate() {
    if (model.username && model.password)
      AuthService()
        .reset_password(model)
        .then((res) => {
          seterror("");
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "mot de passe a été bien changé !",
            showConfirmButton: false,
            timer: 1200,
          });
        })
        .catch((er) => seterror(er.Message));
  }
  return (
    <div>
      <Content>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>
            <Panel
              style={{ background: "#fff" }}
              header={<h3>Changer Mot De Passe</h3>}
              bordered
            >
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Nom d'utilisateur:</Form.ControlLabel>
                  <Form.Control
                    name="username"
                    onChange={(username) => {
                      setmodel((prev) => {
                        return { ...prev, username };
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Nouveau Mot de passe :</Form.ControlLabel>
                  <Form.Control
                    name="password"
                    onChange={(password) => {
                      setmodel((prev) => {
                        return { ...prev, password };
                      });
                    }}
                    autoComplete="off"
                  />
                </Form.Group>
                <div style={{ fontSize: "13px", color: "#aaa" }}>
                  <li>
                    Votre mot de passe doit comporter au moins 8 caractères,
                    dont des lettres majuscules et minuscules, des chiffres et
                    des symboles (ex: * , $ , @)
                  </li>
                </div>
                {error && (
                  <Message showIcon type="error">
                    {error}
                  </Message>
                )}
                <br></br>
                <Form.Group>
                  <ButtonToolbar>
                    <Button appearance="primary" onClick={authenticate}>
                      Changer
                    </Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </div>
  );
}
