import { useEffect, useState } from "react";

export const PriceOverviewItem3 = ({
  pack,
  ident,
  model,
  _setmodel,
  multiple = false,
  onChoose,
}) => {
  // const [data, setdata] = useState(null);
  const [selectedMeal, setselectedMeal] = useState([]);
  const [visa, setvisa] = useState(0);
  const [visaSelected, setvisaSelected] = useState(null);
  const [data, setdata] = useState([]);

  const groupped_by = (arr) => {
    arr = arr
      .filter((el) => !el.isHidden)
      .map((el, i) => {
        return {
          ...el,
          h: el.hotelMakkahName + " - " + el.hotelMadinahName,
          index: i,
        };
      });
    let eles = new Set(arr.map((el) => el.h));

    let res = {};
    eles.forEach((el) => {
      res[el] = arr.filter((p) => p.h === el);
    });

    setdata(res);
  };
  useEffect(() => {
    if (pack.prices) groupped_by(pack.prices);
  }, []);

  return (
    <>
      {data &&
        Object.keys(data).map((k, __i) => (
          <div
            key={__i}
            style={{
              marginBottom: "30px",
              background: "#fff",
              borderRadius: "5px",
              backgroundColor: "rgba(46, 204, 113,0.1)",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <span
                style={{
                  color: "#2ecc71",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  fontSize: 15,
                }}
              >
                <b
                  style={{
                    width: "36px",
                    height: "36px",
                    border: "1px solid #eee",
                    borderRadius: "50%",
                    display: "inline-block",
                    textAlign: "center",
                    boxShadow: "-11px 4px 26px -10px rgba(31,50,150,0.67)",
                    overflow: "hidden",
                  }}
                >
                  <img alt="" src="/hotel.jpg" style={{ width: "50px" }} />
                </b>
                {/* {data[k][0].hotelMakkahName
                  ? data[k][0].hotelMakkahName
                  : "hotel"} */}
                {pack.packageProviders
                  ? pack.packageProviders
                      .filter((el) => el.hotels)[0]
                      .hotels.split(",")
                      .filter((el) => el != "test")
                      .map((el) => <div>{el}</div>)
                  : ""}
              </span>

              <table style={{ borderRadius: "5px !important" }}>
                {data[k].map((p, i) => {
                  return (
                    <tr
                      style={{
                        padding: "5px",
                        borderRadius: "5px !important",
                        background: i % 2 ? "rgb(70,103,209,0.2)" : "",
                      }}
                    >
                      <td
                        style={{
                          padding: "5px",
                        }}
                      >
                        {/* <Tag
                              color={
                                ["red", "green", "yellow"][
                                  roomTypes.findIndex(
                                    (__el) =>
                                      __el.label===
                                      p.arrangementMakkahDesignation
                                  )
                                ]
                              }
                            > */}
                        <strong> {p.name}</strong>
                        {/* </Tag> */}
                      </td>

                      <td>
                        <b style={{ color: "#232323", fontSize: "17px" }}>
                          {p.price}
                          <span
                            style={{
                              color: "#888",
                              fontSize: "11px",
                              fontWeight: "400",
                            }}
                          >
                            TND
                          </span>
                        </b>
                      </td>
                      <td style={{ textAlign: "rigth" }}>
                        <button
                          onClick={(e) => {
                            onChoose({
                              priceId: p.id,
                              totalPrice:
                                selectedMeal.reduce(
                                  (a, b) => a + b.totalPrice,
                                  0
                                ) +
                                p.price -
                                (model.withoutVisa ? visa : 0),
                              arrangement: p.name,
                            });
                          }}
                          className="btn-take"
                          style={{ backgroundColor: "#2ecc71" }}
                        >
                          Prendre
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        ))}
      <style jsx>{`
        .btn-take {
          background: rgb(191, 155, 48);
          color: #fff;
          border-radius: 4px;
          padding: 4px 10px;
        }
        .btn-take:hover {
          opacity: 0.7;
        }
      `}</style>
    </>
  );
};
