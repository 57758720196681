export default class SaleVoModel {
  hotelName = "";
  arrangement = "";
  ticketNumber = "";
  totalPrice = 0;
  regulated_value = 0;
  note = "";
  dateVol = null;
  date = null;
  clientId = 0;
  packageId = 0;
  extrats = [];
  customer = new CustomerVoModel();
}

export class CustomerVoModel {
  name = "";
  passportNumber = "";
  birthDate = new Date();
  gender = 1;
  ageType = 1;
  phoneNumber = "";
  passportExpiryDate = new Date();
  passportDeliveryDate;
  mrz = "";
  grandFatherName;
  arabicMotherName;
  cin;
  sex;
  birthCountry;
  civilState;
  picture;
  nationality;
  passportDeliveryCity;
 
  passportExpiryDate;
  passportDeliveryCountry="TN";
  passportType;

  addressCountry;
  tel;


  addressCity;

  title;


  firstName="";
  lastName="";
  fatherName="";
  motherName="";
  arabicName="";
  arabicLastName="";
  birthCity;
  arabicFatherName="";
  arabicGrandFatherName="";
  job;
  customerType=1
}
